/* Base navbar styles */
body {
  overflow-x: hidden;
}
.navbar-buttons {
  display: block !important;
}
.navbar {
  transition: background-image 0.3s, color 0.3s;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
}

/* Transparent navbar (for sections with background images) */
.navbar-transparent {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

/* Blue navbar (for sections with solid colors) */
.navbar-blue {
  background-image: url('../../assets/images/subtle-prism1.png');
  color: white;
}

/* Logged-in navbar with background image */
.navbar-logged-in {
  background-image: url('../../assets/images/subtle-prism1.png'); /* Replace with your image path */
  background-size: cover; /* Ensure the image covers the entire navbar */
  color: white;
}

/* Center navigation links */
.navbar-nav {
  flex-grow: 1;
  justify-content: center;
}

/* Navbar buttons on the right
.navbar-buttons {
  display: flex;
  align-items: center;
} */

/* Navbar buttons margin */
.navbar-buttons .btn {
  margin-left: 10px;
}

/* General styles for nav links */
.navbar-nav .nav-link {
  font-size: 18px; /* Increase font size */
  color: rgb(8, 8, 8);
  padding: 10px 15px;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
}

/* Hover effect for nav links */
.navbar-nav .nav-link:hover {
  color: #1100ff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

/* Active nav link */
.navbar-nav .nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid #ffd700;
  color: #ffd700;
}

/* Adding a subtle underline effect on hover */
.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #ffd700;
  transition: width 0.3s ease;
}

/* Extend underline on hover */
.navbar-nav .nav-link:hover::after {
  width: 100%;
}

/* Logo styling */
.navbar-logo {
  height: 50px;
  width: auto;
}

/* Custom button styles */
.custom-btn {
  width: 80px;
  border-radius: 30px;
  background: linear-gradient(45deg, #0570ea, #3f67ac);
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
              0px 8px 12px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 10px 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2),
              0px 12px 16px rgba(0, 0, 0, 0.3);
}

.custom-btn:active {
  transform: translateY(-1px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15),
              0px 6px 10px rgba(0, 0, 0, 0.25);
}

/* User icon styling */
.user-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
}
/* Add this to your CSS file */
.logout-button {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show logout button when hovering over the user icon */
.user-icon:hover + .logout-button {
  opacity: 1;
  visibility: visible;
}

/* Hide navbar toggler for mobile view */
.navbar-toggler {
  display: none;
}

/* Custom styles for the Login/Register button in mobile view */
@media (max-width: 991.98px) {
  .btn-primary.d-lg-none {
    display: inline-block;
    margin-left: auto;
  }
}
.mobile-user-menu {
  position: absolute; /* To position it relative to the user icon */
  top: 50px; /* Adjust based on your design */
  right: 0; /* Aligns the menu to the right */
  background-color: white; /* Adjust background color */
  border-radius: 8px; /* Optional: adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds shadow */
  padding: 10px; /* Spacing inside the menu */
  z-index: 1000; /* Ensures it stays above other elements */
}

.mobile-user-menu button {
  display: block; /* Makes buttons stack vertically */
  width: 100%; /* Makes buttons take full width */
  padding: 10px; /* Spacing inside buttons */
  border: none; /* Removes border */
  background: none; /* Removes background */
  text-align: left; /* Align text to the left */
  cursor: pointer; /* Changes cursor to pointer */
}

.mobile-user-menu button:hover {
  background-color: #f0f0f0; /* Optional: adds a hover effect */
}
